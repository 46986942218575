<template>
    <svg width="204" height="18" viewBox="0 0 204 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_17_61)">
            <path d="M179.422 9.81818H0V8.18182H179.422V0L204 9L179.422 18V9.81818Z" fill="#F7F5F7"/>
        </g>
        <defs>
            <clipPath id="clip0_17_61">
                <rect width="216" height="17" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: "arrow"
    }
</script>

<style scoped>

</style>