<template>
    <svg version="1.1" id="delivery" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 150" style="enable-background:new 0 0 150 150;" xml:space="preserve">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
	        <path class="st0" d="M813.19,5016c-8.31-5.77-11.43-15.2-8.05-23.85c1.3-4.19,21.04-25.94,43.39-48.48l40.79-41.4H565.07H240.82l-6.24-6.55l-6.5-6.29V4725.4v-164.04l6.5-6.29c7.79-8.12,18.97-8.39,28.32-1.05l6.76,5.24v149.36v149.1h309.7h309.96l-41.83-42.45c-23.12-23.58-42.61-44.81-43.39-47.17c-2.08-6.29,2.86-19.92,8.57-23.06c2.6-1.57,8.57-2.62,13.25-2.62c7.79,0,15.07,6.55,71.71,63.68c34.82,34.85,63.91,66.03,64.69,69.18c0.78,3.14,0.78,8.39-0.26,11.79c-2.34,7.07-116.4,123.95-126.53,129.45C826.45,5021.24,820.47,5021.24,813.19,5016z"/>
            <path class="st0" d="M1172.26,5016.26c-65.47-11.79-122.37-57.65-147.57-118.7c-27.54-66.03-18.19-140.72,32.74-263.88c25.98-63.15,78.72-166.4,122.63-240.29c13.51-22.8,15.85-25.42,24.16-26.99c16.89-3.14,23.64,5.77,71.71,93.02c61.84,112.42,111.46,230.07,127.83,302.92c6.24,27.51,7.01,34.85,5.72,63.94c-2.08,55.81-18.71,93.29-58.72,133.9C1302.68,5008.66,1239.03,5028.58,1172.26,5016.26z M1247.34,4971.45c46.51-12.05,83.92-43.76,104.19-87.78c22.34-48.74,18.97-96.69-13.51-185.79c-14.81-40.35-39.23-96.43-62.88-144.12c-15.59-31.71-64.95-123.16-66.25-123.16c-1.82,0-48.59,87-68.85,128.14c-71.71,144.38-97.43,233.74-84.7,291.91c5.2,23.32,17.93,50.57,31.96,67.34c20.53,25.16,53.26,46.12,83.4,53.46C1190.96,4976.7,1228.12,4976.43,1247.34,4971.45z"/>
            <path class="st0" d="M1177.45,4930.05c-35.59-12.05-60.8-36.16-73.79-71.8c-6.76-17.82-7.53-48.48-1.82-67.87c24.16-81.49,126.53-107.96,185.77-47.95c54.82,55.81,37.93,147.79-33.26,181.86c-14.55,6.81-20.27,8.12-41.57,8.65C1197.46,4933.2,1184.21,4932.15,1177.45,4930.05z M1228.12,4887.6c26.5-7.86,49.62-37.73,49.62-64.2c0-37.47-27.28-67.08-64.69-69.96c-14.03-0.79-19.75,0-30.92,4.72c-23.9,10.48-42.09,37.73-42.09,63.15c0,26.99,21.3,56.34,47.29,65.51C1204.47,4892.84,1210.71,4892.84,1228.12,4887.6z"/>
            <path class="st0" d="M459.33,4604.33c-46.51-24.89-148.09-79.4-226.04-120.8c-77.94-41.4-144.2-77.83-147.57-80.97L80,4396.8v-209.63v-209.63l8.57-6.29c23.12-17.56,453.89-288.77,461.69-290.87c8.83-2.36,18.71,2.88,237.73,120.01c125.75,67.34,230.97,124.73,233.83,127.61c5.2,4.72,5.2,8.65,5.98,227.45c0.52,183.69,0,224.04-2.86,231.12c-3.38,7.86-24.42,20.18-235.39,136.52c-157.45,86.74-233.83,127.61-238.77,127.35C546.89,4650.19,505.83,4629.49,459.33,4604.33zM749.8,4495.06c108.08-59.48,199.8-109.8,203.69-111.89c7.01-3.67,6.5-3.93-48.85-34.33l-55.86-30.66L648.21,4428.5c-110.42,60.79-202.14,111.63-203.69,113.2c-2.6,2.36,9.87,10.22,50.66,32.49c29.62,15.98,54.82,29.35,55.86,29.35S641.46,4554.81,749.8,4495.06z M612.36,4399.42l215.91-119.23v-62.1v-62.37l-33-18.34l-33.26-18.34l-1.3,61.06c-1.04,50.05-2.08,61.84-5.46,67.08c-2.86,4.45-74.31,44.28-215.13,120.28c-115.88,62.63-210.71,114.51-210.71,115.3c0,1.83,63.13,36.95,65.21,36.16C395.41,4518.65,493.36,4464.93,612.36,4399.42z M484.01,4346.75c111.46-60.79,203.69-111.37,204.73-112.42c2.08-2.1-131.47-76.78-137.44-77.04c-1.82,0-396.74,223.78-405.57,229.81c-0.78,0.52,133.28,71.54,134.32,71.01C280.84,4457.59,372.55,4407.54,484.01,4346.75z M325.78,4234.07l202.4-115.04l0.78-188.93c0.52-151.2,0-188.67-2.6-187.1c-1.82,1.05-93.79,58.96-203.95,128.92l-200.84,126.83v175.31c0,96.43,0.26,175.31,0.78,175.31C122.87,4349.37,214.32,4297.48,325.78,4234.07z M984.15,4151l-0.26-193.39l-170.44-91.71c-93.79-50.31-186.81-100.36-206.55-110.84l-35.85-19.13v189.72v189.98l71.71,39.57c39.23,21.75,71.97,39.57,72.75,39.57c0.52,0,1.04-27.25,1.04-60.53v-60.53l7.53-7.6c4.16-4.19,10.39-7.6,13.51-7.6c7.27,0,115.1,58.44,125.23,67.61l7.01,6.81v73.9v73.9l56.64,31.45c30.92,17.29,56.64,31.71,57.16,31.71C983.89,4344.13,984.15,4257.13,984.15,4151z"/>
            <path class="st0" d="M186.78,4194.5c-14.03-5.77-17.67-23.32-7.27-34.85c3.12-3.41,65.47-41.14,138.74-83.85c96.65-56.6,135.1-77.56,141.08-77.56c18.97,0,27.8,23.85,13.77,37.21c-8.83,7.86-266.57,158.8-274.36,160.63C195.62,4196.6,190.16,4196.07,186.78,4194.5z"/>
            <path class="st0" d="M176.39,4088.38c-6.76-8.65-7.01-19.65-0.78-26.99c3.64-4.45,236.17-142.29,266.05-157.75c12.99-6.81,21.82-6.29,29.36,1.57c7.79,7.6,8.57,19.39,2.08,26.99c-2.6,3.14-64.43,40.88-137.7,84.38c-114.06,67.34-134.58,78.61-143.42,78.61C183.93,4095.19,180.55,4093.62,176.39,4088.38z"/>
            <path class="st0" d="M1200.32,4251.89c-58.98-59.22-69.89-72.59-66.25-82.54c3.38-9.96,11.95-16.51,21.04-16.51c8.05,0,12.99,4.19,51.44,42.45l42.61,42.45v-240.29v-240.29h-177.45c-175.11,0-177.71,0-183.17-5.24c-7.53-7.08-7.79-24.11-0.52-31.45c5.2-5.24,8.57-5.24,199.54-5.24c194.6,0,194.6,0,199.54,5.5c4.68,5.24,4.94,13.89,5.72,261.26l0.52,255.49l42.61-42.19c38.19-38.26,43.39-42.45,51.18-42.45c16.63,0.26,27.02,16.25,19.49,30.66c-2.08,3.93-31.18,34.85-64.43,68.39c-58.46,58.44-61.06,60.79-70.93,60.79S1258.78,4310.32,1200.32,4251.89z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "delivery"
    }
</script>

<style scoped>
    .st0{fill:#FF7A00;}
</style>