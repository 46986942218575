<template>
    <svg version="1.1" id="customs" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 150 150" style="enable-background:new 0 0 150 150;" xml:space="preserve">
        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
	        <path class="st0" d="M298.79,5048.55c-23.76-2.14-63.36-9.62-84.48-15.76c-70.69-21.11-95.62-52.64-73.62-92.18 c4.69-8.55,28.75-26.45,35.49-26.45c1.17,0,2.35-3.47,2.35-7.75c0-12.02,7.92-26.19,20.83-37.41l11.73-10.42l-5.87-10.42 c-9.39-16.3-14.96-40.35-14.96-64.13c0-29.66,4.11-46.23,18.48-72.68c21.12-39.81,62.77-70.54,110.29-81.76 c23.76-5.61,66.29-5.61,89.46,0c58.37,14.16,107.94,59.32,123.49,112.49c9.09,30.73,4.69,81.5-9.09,105.81 c-4.69,8.02-4.69,8.02,7.92,19.24c13.49,12.02,22.88,28.32,23.17,39.55c0,5.61,2.64,9.08,11.15,13.89 c39.01,22.71,44.58,59.59,12.91,84.97c-23.17,18.17-79.78,35.54-138.45,42.22C416.71,5050.15,322.85,5050.95,298.79,5048.55z M432.84,5003.66c54.85-6.68,100.61-19.77,113.81-33.13c5.87-6.15,5.87-6.15,0-10.15c-3.23-2.14-11.15-6.41-17.6-9.35 c-6.45-2.94-14.37-7.75-17.89-10.42c-5.28-4.54-7.92-4.81-20.53-2.67c-53.68,9.35-173.06,10.15-234.36,1.87 c-21.12-2.94-27.28-2.94-30.8-0.27c-2.35,1.87-10.85,6.41-19.07,10.15c-7.92,3.74-17.01,9.08-19.65,11.76 c-4.99,5.08-4.99,5.34,1.47,10.96c14.96,12.83,66.88,27.25,116.45,32.33C341.03,5008.47,396.18,5007.93,432.84,5003.66z M456.89,4897.58c32.56-2.94,33.15-3.21,26.4-7.75c-3.81-2.67-17.01-7.75-29.04-11.49c-61.3-18.44-143.14-15.23-197.99,8.02 l-17.6,7.21l19.07,2.4C317.86,4903.46,388.26,4903.99,456.89,4897.58z M287.06,4827.84c37.25-8.55,116.16-8.28,155.17,0.53 c31.09,6.95,29.63,7.48,36.96-14.16c5.57-16.83,5.57-52.64,0-69.47c-17.01-51.3-76.56-84.7-136.39-76.42 c-68.05,9.62-115.86,68.14-105.01,129.06c3.52,21.38,13.49,40.08,19.65,37.94C260.07,4834.25,273.27,4831.05,287.06,4827.84z"/>
            <path class="st0" d="M207.86,4594.31c-34.61-5.34-74.8-27.25-96.8-52.1c-6.75-7.75-17.01-22.44-22.29-32.87C69.42,4472.2,70,4481.82,70,4236.26c0-219.1,0-222.58,5.87-227.92c5.28-4.81,9.68-5.34,57.2-5.34h51.33v-132.26c0-126.65,0.29-132.8,5.87-146.69c6.75-16.03,20.24-28.59,39.31-36.61c11.15-4.54,21.71-5.08,133.75-5.08c73.92,0,125.84,1.07,132.58,2.67c14.37,3.74,38.43,24.05,45.17,37.94c5.28,10.69,5.57,21.64,6.45,147.23l0.88,135.47h51.04c47.81,0,51.62,0.27,57.2,5.34c5.87,5.34,6.16,7.48,5.28,229.26l-0.88,223.65l-8.21,21.38c-19.36,49.97-53.97,84.17-103.54,102.07l-20.24,7.48l-156.93,0.27C285.89,4595.38,211.97,4595.11,207.86,4594.31z M529.34,4545.68c16.72-5.34,40.77-19.77,46.05-27.25c2.35-3.47-31.09-41.95-168.95-194.25l-171.89-189.98l-0.88,63.59c-0.88,57.18-1.47,64.4-6.16,69.2c-10.85,10.69-33.44,8.55-39.89-3.74c-2.35-4.54-3.23-38.74-3.23-110.62v-104.21h-33.73h-34.03l0.88,205.21l0.88,204.94l8.21,18.7c14.37,33.13,36.67,55.04,68.34,66.8l17.31,6.68h150.47C504.12,4550.76,514.09,4550.49,529.34,4545.68z M612.35,4448.15c2.05-10.69,3.23-80.43,3.23-207.88v-191.85h-35.2h-35.2v104.21c0,72.68-0.88,106.08-3.52,110.62c-5.57,11.76-28.16,14.16-38.72,4.28c-6.16-5.61-6.16-5.88-7.04-265.86l-0.59-259.99l-9.09-6.95c-8.8-6.68-9.97-6.95-52.8-6.95h-43.7v145.89c0,89.78-1.17,147.49-2.93,150.7c-3.52,6.15-17.31,10.42-27.57,8.82c-16.72-2.94-16.43,0.53-16.43-160.32v-147.76h-42.53c-45.17,0-56.61,2.4-63.06,13.09c-1.76,3.47-2.93,60.39-2.93,164.59v159.25l185.38,204.41c125.25,138.68,185.97,203.34,187.43,200.93C608.25,4465.52,610.59,4456.7,612.35,4448.15z"/>
            <path class="st0" d="M261.54,4531.52c-3.52-3.47-12.32-17.37-19.65-30.73c-9.97-18.44-15.25-25.12-20.24-25.92c-3.52-0.8-16.43-2.67-28.45-4.28c-29.33-4.01-36.96-6.95-41.07-16.3c-5.57-12.29-1.76-19.24,24.05-41.15l23.76-20.57l-5.28-25.65c-2.93-14.16-5.28-29.39-5.28-33.67c0-9.35,12.32-20.31,23.17-20.31c3.81,0,20.24,5.88,36.08,13.09l28.75,13.36l28.75-13.63c25.81-12.02,29.92-13.36,39.01-11.22c5.57,1.34,12.91,5.61,15.84,9.35c5.57,6.68,5.57,7.48,0.59,37.68l-4.99,31l23.47,20.31c24.93,21.38,29.92,29.66,24.35,41.95c-4.11,8.82-12.32,12.02-42.53,15.76c-12.91,1.6-26.11,4.01-29.33,5.08c-3.23,1.34-11.73,12.29-18.77,24.58c-7.04,12.29-16.13,25.65-20.24,29.93C283.83,4539.27,270.05,4540.07,261.54,4531.52zM295.27,4436.39c2.64-1.34,11.44-3.47,19.36-4.54l14.08-1.87l-8.8-8.28c-11.73-11.22-15.55-21.11-13.49-34.2c1.17-5.61,1.17-10.42,0.29-10.42c-1.17,0-7.92,2.67-15.25,5.88l-13.2,5.88l-15.84-6.95l-15.84-7.21l2.64,9.89c4.4,17.1,2.93,21.64-9.39,33.93l-11.73,11.76l12.91,1.6c14.67,2.14,25.52,9.89,31.39,22.71l3.81,8.55l7.04-12.02C287.06,4444.14,292.34,4437.73,295.27,4436.39z"/>
            <path class="st0" d="M738.91,4797.05c-5.01-5.93-5.01-9.78-5.01-450.56s0-444.63,5.01-450.56c5.01-5.93,8.27-5.93,346.98-5.93s341.97,0,346.98,5.93c5.01,5.93,5.01,9.78,5.01,450.56s0,444.63-5.01,450.56c-5.01,5.93-8.27,5.93-346.98,5.93S743.92,4802.98,738.91,4797.05z M1397.79,4346.49v-409.06h-311.9h-311.9v409.06v409.06h311.9h311.9V4346.49z"/>
            <path class="st0" d="M839.87,4696.26c-3.01-1.48-6.51-5.34-8.02-8.6c-1.75-3.85-2.76-47.13-2.76-128.35c0.25-131.91,0-129.54,13.53-135.76c9.02-3.85,252.53-4.15,264.3,0c15.53,5.04,15.03,1.48,15.28,135.46c0,66.99-0.75,124.2-1.5,126.87c-4.26,13.04-8.52,13.34-145.55,13.04C903.76,4698.93,842.88,4697.75,839.87,4696.26zM1082.13,4559.91v-88.93H975.66H869.19v88.93v88.93h106.47h106.47V4559.91z"/>
            <path class="st0" d="M1160.04,4678.18c-11.02-5.04-15.78-19.56-11.27-33.79c4.26-12.45,11.02-13.34,111.98-13.34c93.7,0,96.45,0.3,102.97,6.22c4.76,4.45,6.51,8.89,6.51,16.3c0,14.23-3.51,20.75-12.78,24.6C1347.44,4682.33,1168.81,4682.33,1160.04,4678.18z"/>
            <path class="st0" d="M1152.78,4523.45c-7.01-8.89-7.01-22.53-0.25-32.9l5.01-7.71H1259c97.95,0,101.46,0.3,106.22,5.93c7.27,8.6,6.76,26.68-0.75,34.98c-5.76,6.52-6.51,6.52-105.97,6.52h-100.46L1152.78,4523.45z"/>
            <path class="st0" d="M814.07,4375.54c-7.01-9.19-6.51-24.6,1.25-33.5l6.01-7.41h264.05c263.3,0,264.05,0,270.82,6.22c8.27,7.71,9.27,26.08,1.5,35.27c-5.01,5.93-8.27,5.93-271.82,5.93H819.33L814.07,4375.54z"/>
            <path class="st0" d="M815.32,4226.44c-8.27-9.49-8.52-24.6-0.5-33.5l5.76-6.52h266.06c262.8,0,266.06,0,271.07,5.93c7.27,8.6,6.76,26.68-0.75,34.98l-5.76,6.52h-265.05h-264.8L815.32,4226.44z"/>
            <path class="st0" d="M820.08,4081.49c-4.26-2.37-8.52-6.22-9.52-9.19c-4.76-14.82,2-32.01,14.28-35.57c3.01-0.89,123.26-1.19,266.81-0.89l261.55,0.89l4.76,7.11c5.26,7.41,6.26,21.05,2.25,29.94c-1.25,2.96-6.01,6.82-10.27,8.6C1337.16,4087.71,830.35,4086.82,820.08,4081.49z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "customs"
    }
</script>

<style scoped>
    .st0{fill:#FF7A00;}
    .st1{fill:#FF7A00;stroke:#FF7A00;stroke-width:0.8;stroke-miterlimit:10;}
</style>