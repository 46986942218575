<template>
  
<svg width="105px" height="15px" viewBox="0 0 105 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <text id="text-uobxwhtls--1" font-family="ChalkboardSE-Regular, Chalkboard SE" font-size="19" font-weight="normal">
            <tspan x="0" y="14">Gruz-Line24</tspan>
        </text>
        <filter x="-6.9%" y="-25.0%" width="113.8%" height="150.0%" filterUnits="objectBoundingBox" id="filter-uobxwhtls--2">
            <feGaussianBlur stdDeviation="2.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
            <feOffset dx="10" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0.568627451   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
        </filter>
        <filter x="-6.9%" y="-25.0%" width="113.8%" height="150.0%" filterUnits="objectBoundingBox" id="filter-uobxwhtls--3">
            <feGaussianBlur stdDeviation="2.5" in="SourceAlpha" result="shadowBlurInner1"></feGaussianBlur>
            <feOffset dx="10" dy="2" in="shadowBlurInner1" result="shadowOffsetInner1"></feOffset>
            <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
            <feColorMatrix values="0 0 0 0 0   0 0 0 0 0.568627451   0 0 0 0 1  0 0 0 1 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
        </filter>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Gruz-Line24" fill-rule="nonzero" fill="#D8D8D8">
            <use xlink:href="#text-uobxwhtls--1"></use>
            <use fill-opacity="1" filter="url(#filter-uobxwhtls--2)" xlink:href="#text-uobxwhtls--1"></use>
            <use fill-opacity="1" filter="url(#filter-uobxwhtls--3)" xlink:href="#text-uobxwhtls--1"></use>
        </g>
    </g>
</svg>
</template>

<script>
    export default {
        name: "logo"
    }
</script>

<style scoped>
    .st0{fill:#0F0F0F;}
    .st1{fill:#FFFFFF;}
</style>