<template>
    <svg version="1.1" id="phone" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 800 800" style="enable-background:new 0 0 800 800;" xml:space="preserve">
        <g>
	        <path class="st0" fill="#71bbfb" d="M163,531.8c78.9,94.3,173.9,168.6,282.3,221.1c41.3,19.6,96.5,42.8,158,46.7c3.8,0.2,7.5,0.3,11.3,0.3c41.3,0,74.4-14.3,101.5-43.6c0.2-0.2,0.5-0.5,0.7-0.8c9.6-11.6,20.6-22,32-33.2c7.8-7.5,15.7-15.3,23.4-23.2c35.3-36.8,35.3-83.5-0.3-119.2l-99.6-99.6c-16.9-17.6-37.1-26.9-58.4-26.9c-21.2,0-41.6,9.3-59,26.7l-59.3,59.3c-5.5-3.1-11.1-6-16.4-8.6c-6.6-3.3-12.8-6.5-18.2-9.9c-54-34.3-103.1-79.1-150-136.6c-23.7-30-39.6-55.2-50.7-80.9c15.6-14.1,30.2-28.8,44.3-43.3c5-5.1,10.1-10.3,15.3-15.4c17.9-17.9,27.5-38.6,27.5-59.7s-9.4-41.8-27.5-59.7l-49.4-49.4c-5.8-5.8-11.3-11.4-16.9-17.2c-10.9-11.3-22.4-22.9-33.7-33.3C202.5,8.8,182.4,0,161.2,0c-21.1,0-41.3,8.8-59,25.7l-62,62c-22.5,22.5-35.3,49.9-38,81.6c-3.1,39.6,4.1,81.7,23,132.6C54.3,380.6,98,453.7,163,531.8z M42.7,172.7c2-22,10.4-40.4,26.4-56.4l61.7-61.7c9.6-9.3,20.2-14.1,30.5-14.1c10.1,0,20.4,4.8,29.8,14.4c11.1,10.3,21.5,21.1,32.8,32.5c5.6,5.8,11.4,11.6,17.2,17.6l49.4,49.4c10.3,10.3,15.6,20.7,15.6,31c0,10.3-5.3,20.7-15.6,31c-5.1,5.1-10.3,10.4-15.4,15.6c-15.4,15.6-29.8,30.3-45.8,44.4c-0.3,0.3-0.5,0.5-0.8,0.8c-13.8,13.8-11.6,26.9-8.3,36.8c0.2,0.5,0.3,0.8,0.5,1.3c12.8,30.7,30.5,59.8,58.2,94.7C328.6,471.4,381,519,438.7,555.7c7.1,4.6,14.8,8.3,21.9,11.9c6.6,3.3,12.8,6.5,18.2,9.9c0.7,0.3,1.2,0.7,1.8,1c5.5,2.8,10.8,4.1,16.1,4.1c13.3,0,21.9-8.5,24.7-11.3l62-62c9.6-9.6,20.1-14.8,30.3-14.8c12.6,0,22.9,7.8,29.3,14.8l100,99.8c19.9,19.9,19.7,41.4-0.5,62.5c-7,7.5-14.3,14.6-22,22c-11.6,11.3-23.7,22.9-34.6,36c-19.1,20.6-41.8,30.2-71.1,30.2c-2.8,0-5.8-0.2-8.6-0.3c-54.4-3.5-104.9-24.7-142.9-42.8c-103.1-49.9-193.6-120.7-268.7-210.5c-61.8-74.4-103.4-143.7-131-218C46.5,242.7,40,206.1,42.7,172.7z"/>
        </g>
    </svg>
</template>

<script>
    export default {
        name: "phone"
    }
</script>

<style scoped>
    .st0{fill:#FFFFFF;}
</style>