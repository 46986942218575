<template>
    <div id="Types">
        <b-container>
            <h2>Виды грузоперевозок</h2>
            <b-card no-body>
                <b-tabs pills card vertical>
                    <b-tab v-for="(types, index) in Types" :title="types.name" :key="index">
                        <b-card-text>
                            <h3>{{types.name}}</h3>
                            <p>{{types.text}}</p>
                            <p>Преимущества:</p>
                            <ul>
                                <li v-for="(feature,index) in types.list" :key="index">{{feature}}</li>
                            </ul>
                        </b-card-text>
                    </b-tab>
                </b-tabs>
            </b-card>
        </b-container>
    </div>
</template>

<script>
    export default {
        name: "Types",
        data(){
            return{
                Types:[
                    {
                        name:'Экспресс-перевозки',
                        text:'Используется при доставке дорогих или скоропортящихся товаров.',
                        list:[
                            'Самое быстрое время доставки. Расстояние между странами преодолевается за часы',
                            'Минимальный риск порчи, повреждений и полной утраты груза',
                            'Возможность отправки товаров с любыми характеристиками. Размеры лайнера вмещают разные объекты'
                        ]
                    },
                    {
                        name:'Автоперевозки',
                        text:'Автомобильные перевозки позволяют привезти как скоропортящиеся продукты, так и практически все виды оборудования.',
                        list:[
                            'Высока скорость доставки',
                            'Оптимальный выбор маршрута. Автомобильные дороги проложены во всех уголах стран',
                            'Выбор соответствующего авто под конкретный груз (учитывая габариты и веса груза)',
                            'Возможные отклонения от сроков минимальны'
                        ]
                    },
                    {
                        name:'Перевозки паллет',
                        text:'В основном перевозки паллет используются для поставки крупногабаритных грузов или больших партий.',
                        list:[
                            'Всепогодность. Неблагоприятных погодных условий при такой грузоперевозки не существует',
                            'Развитая сеть доставки по России и Китая',
                            'Грузоподъемность. В одном паллете возможно перевозить 1 тонну груза'
                        ]
                    },
                    {
                        name:'Объемные грузы',
                        text:'Используется для больших партий товаров и габаритных грузов.',
                        list:[
                            'Самая низкая стоимость среди всех грузоперевозок',
                            'Простая логистика',
                            'Минимизация рисков внешнего воздействия на груз'
                        ]
                    },
                ]
            }
        }
    }
</script>

<style scoped>

</style>